import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Layout({ children }) {
  return (
    <>
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
