import React from 'react';
import { Link } from 'gatsby';

import logoImg from '../images/logo.svg';

function Footer() {
  return (
    <footer className="bg-white">
      <div className="container mx-auto  px-8">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 mb-6">
            <Link to="/">
              <img className="w-4/5" src={logoImg} alt="sofortshop Logo" />
            </Link>
          </div>

          <div className="flex-1"></div>

          <div className="flex-1"></div>

          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Rechtliches</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/impressum/"
                  className="block md:inline-block mt-4 md:mt-0 no-underline text-black hover:text-gray-800"
                >
                  Impressum
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/datenschutzerklaerung/"
                  className="block md:inline-block mt-4 md:mt-0 no-underline text-black hover:text-gray-800"
                >
                  Datenschutzerklärung
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Links</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/"
                  className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
                >
                  Übersicht
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/funktionen/"
                  className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
                >
                  Funktionen
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/preis/"
                  className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
                >
                  Preis
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/faq/"
                  className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
                >
                  FAQ
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/bestellformular/"
                  className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
                >
                  Bestellen
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/kontakt/"
                  className="block md:inline-block mt-4 md:mt-0 no-underline text-black hover:text-gray-800"
                >
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
