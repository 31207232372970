import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import logoImg from "../images/logo.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav
      id="header"
      className="fixed w-full z-30 top-0 text-white bg-white shadow"
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <a
            className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
            href="/"
          >
            <img className="h-8 md:h-10 m-1" src={logoImg} alt="sofortshop Logo" />
          </a>
        </div>

        <button
          className="block md:hidden border border-gray-800 flex items-center px-3 py-2 mr-4 rounded text-white hover:text-white bg-gray-800"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menü</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto pb-4 md:pb-0`}
        >
          <div className="ml-6 md:ml-0">
            <Link
              to="/"
              className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              Übersicht
            </Link>

            <Link
              to="/funktionen/"
              className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              Funktionen
            </Link>

            <Link
              to="/preis/"
              className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              Preis
            </Link>

            <Link
              to="/faq/"
              className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              FAQ
            </Link>

            <Link
              to="/bestellformular/"
              className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              Bestellen
            </Link>

            <Link
              to="/kontakt/"
              className="block md:inline-block mt-4 md:mt-0 no-underline text-black hover:text-gray-800"
              activeClassName="font-bold"
            >
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
